/* You can add global styles to this file, and also import other style files */
img.wp-smiley,
img.emoji {
    display: inline !important;
    border: none !important;
    box-shadow: none !important;
    height: 1em !important;
    width: 1em !important;
    margin: 0 0.07em !important;
    vertical-align: -0.1em !important;
    background: none !important;
    padding: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Work Sans', sans-serif;
}

body,
textarea,
input {
    font-family: 'Work Sans', sans-serif;
}

.shb-booking-page-main .woocommerce form.woocommerce-form-login .form-row .woocommerce-form-login__submit,
.shb-booking-page-main .woocommerce form.checkout_coupon p .button,
.shb-booking-page-main .woocommerce #payment #place_order,
.woocommerce-account .vbogs-content-wrapper .vbogs-main-content a.woocommerce-button,
.woocommerce-account .vbogs-content-wrapper .vbogs-main-content .woocommerce-Button,
.woocommerce-account .vbogs-content-wrapper .vbogs-main-content .woocommerce-Button.disabled,
.woocommerce-account .vbogs-content-wrapper .vbogs-main-content .button,
.vbogs-content-wrapper .woocommerce ul.products li.product .button,
.single-product .vbogs-blog-single-wrapper .single_add_to_cart_button.button.alt,
.woocommerce-cart .vbogs-content-wrapper .vbogs-main-content .checkout-button,
.woocommerce-cart .vbogs-content-wrapper .vbogs-main-content .checkout-button:hover,
.shb-accommodation-listing-filter-sorting .shb-accommodation-listing-sorting select {
    font-family: 'Work Sans', sans-serif !important;
}

.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-left-wrapper li.vbogs-phone-icon:before,
.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-left-wrapper li.vbogs-map-icon:before,
.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button2,
.vbogs-header .vbogs-navigation ul li ul li:last-child a:hover,
.vbogs-header .vbogs-navigation li ul li a:hover,
.vbogs-header .vbogs-fixed-navigation-show .vbogs-navigation li ul li a:hover,
.vbogs-header .vbogs-navigation li ul li.current_page_item a:hover,
.vbogs-header .vbogs-top-right-navigation li li a:hover,
.vbogs-header .vbogs-mobile-navigation-wrapper .vbogs-top-right-button2,
.vbogs-page-header h1:after,
.vbogs-blog-wrapper-1 .vbogs-blog-block .vbogs-blog-description .vbogs-more-link,
.vbogs-footer-wrapper .vbogs-footer-bottom-wrapper,
h4.vbogs-comment-count-title:after,
.vbogs-comments-wrapper #respond #reply-title:after,
.vbogs-main-content input[type="submit"],
h3.vbogs-title1:after,
.vbogs-sidebar-content .vbogs-widget-wrapper h3:after,
.vbogs-footer-social-icons-wrapper a,
body .tnp-widget input[type="submit"],
body .tnp-widget-minimal input[type="submit"],
.vbogs-page-header .vbogs-search-results-form button,
.vbogs-page-not-found h1:after,
.vbogs-page-not-found form button,
body div.pp_default .pp_close,
.vbogs-title-left:after,
.vbogs-title-center:after,
.vc_toggle_size_md.vc_toggle_default .vc_toggle_title h4:before,
.wpb-js-composer .vc_tta-accordion.vc_tta.vc_general .vc_tta-panel h4.vc_tta-panel-title:before,
.vbogs-social-links a,
.vbogs-testimonial-wrapper.vbogs-testimonial-wrapper-dark .owl-theme .owl-dots .owl-dot.active span,
.vbogs-hotel-listing-wrapper .vbogs-hotel-listing h3:after,
.owl-theme .owl-dots .owl-dot.active span,
.vbogs-main-content table th,
.vbogs-call-to-action-video-section .vbogs-call-to-action-video-section-inner h3:after,
.vbogs-call-to-action-2-section h3:after,
.wp-block-button__link,
body .vbogs-slider-caption-1 .vbogs-slider-button,
body .vbogs-slider-caption-2 .vbogs-slider-button,
.shb-booking-form-with-background-1 .shb-booking-form-with-background-1-content h2:after,
.shb-booking-form-with-background-2 .shb-booking-form-with-background-2-content h2:after,
.shb-booking-form-with-background-3 .shb-booking-form-with-background-3-content h2:after,
.shb-booking-form-with-background-3-wrapper .shb-booking-form-wrapper .shb-booking-form-button-1,
body .vbogs-slider-caption-3 h2:after,
body .vbogs-slider-caption-3 .vbogs-slider-button,
body .vbogs-slider-caption-4 h2:after,
body .vbogs-slider-caption-4 .vbogs-slider-button,
.vbogs-page-pagination .wp-pagenavi .current,
.vbogs-page-pagination .wp-pagenavi a:hover,
.vbogs-loading div:after {
    background: #7F9A27;
}

body .vbogs-button1 {
    background: #7F9A27 !important;
}

.vbogs-blog-wrapper-1 .vbogs-blog-block .vbogs-blog-meta i,
.vbogs-blog-single-wrapper .vbogs-blog-meta i,
.vbogs-main-content .vbogs-comments-wrapper .vbogs-comments p.vbogs-comment-info a,
.vbogs-main-content .vbogs-comments-wrapper .comment-reply-link,
.vbogs-main-content .vbogs-comments-wrapper .comment-edit-link,
.vbogs-blog-wrapper-2 .vbogs-blog-block .vbogs-blog-meta span i,
.vbogs-blog-wrapper-3 .vbogs-blog-block .vbogs-blog-meta i,
blockquote:before,
.vbogs-widget .vbogs-contact-widget li:before,
.vbogs-icon-text-wrapper-1 .vbogs-icon-text-block .vbogs-icon i,
.vbogs-icon-text-wrapper-2 .vbogs-icon i,
.vbogs-contact-details-list li:before,
.vbogs-main-content div.wpcf7 .ajax-loader:after,
.vbogs-testimonial-wrapper .vbogs-testimonial-block div span.vbogs-open-quote,
.vbogs-testimonial-wrapper .vbogs-testimonial-block div span.vbogs-close-quote,
.vbogs-blog-carousel .vbogs-blog-block .vbogs-blog-date i,
.vbogs-single-testimonial-text span,
.vbogs-main-content ul li:before,
.vbogs-main-content ul.vbogs-contact-details-list li::before {
    color: #7F9A27;
}

.vbogs-page-pagination .wp-pagenavi .current,
.vbogs-page-pagination .wp-pagenavi a:hover {
    border: #7F9A27 1px solid;
}

body .tnp-widget input[type="text"],
body .tnp-widget input[type="email"],
body .tnp-widget-minimal input[type="text"],
body .tnp-widget-minimal input[type="email"],
.vbogs-blog-block.sticky,
.vbogs-blog-block.tag-sticky-2,
.is-style-outline>.wp-block-button__link:not(.has-background) {
    border: #7F9A27 2px solid;
}

.wpb-js-composer .vc_tta-color-grey.vc_tta-style-classic ul.vc_tta-tabs-list li.vc_tta-tab.vc_active {
    border-top: #7F9A27 4px solid;
}

.shb-accommodation-listing-style-1 .shb-accommodation-listing-item .shb-accommodation-listing-description-wrapper .shb-accommodation-listing-description .shb-accommodation-listing-button1,
.shb-accommodation-listing-style-2 .shb-accommodation-listing-item .shb-accommodation-listing-image .shb-accommodation-listing-button1,
.shb-accommodation-listing-style-3 .shb-accommodation-listing-item .shb-accommodation-listing-image .shb-accommodation-listing-button1,
.shb-booking-form-style-1 .shb-booking-form-col input[type="submit"],
.shb-guestclass-select-dropdown .shb-qty-done,
.vbogs-content-wrapper .shb-booking-contact-wrapper h3:after,
.shb-booking-step-wrapper .shb-booking-step.shb-booking-step-current a:first-child,
.shb-booking-step-wrapper .shb-booking-step-line div,
.shb-booking-accommodation-result-wrapper .shb-booking-accommodation-item .shb-booking-accommodation-info-wrapper .shb-booking-accommodation-info.shb-booking-accommodation-rate .shb-booking-accommodation-select-room,
.shb-booking-your-stay-wrapper .shb-booking-your-stay-item-wrapper h3:after,
.shb-booking-continue,
.shb-lightbox-close,
#ui-datepicker-div .ui-datepicker-calendar tbody td[data-event="click"]:hover,
.shb-booking-complete-wrapper i,
.shb-booking-page-main .woocommerce form.woocommerce-form-login .form-row .woocommerce-form-login__submit,
.shb-booking-page-main .woocommerce form.checkout_coupon p .button,
.woocommerce-page .select2-container--default .select2-results__option--highlighted[aria-selected],
.woocommerce-page .select2-container--default .select2-results__option--highlighted[data-selected],
.woocommerce-account .vbogs-content-wrapper .vbogs-main-content form .select2-container--default .select2-results__option--highlighted[aria-selected],
.woocommerce-account .vbogs-content-wrapper .vbogs-main-content form .select2-container--default .select2-results__option--highlighted[data-selected],
.shb-booking-page-main .woocommerce #payment #place_order,
.woocommerce-account .vbogs-content-wrapper .vbogs-main-content a.woocommerce-button,
.woocommerce-account .vbogs-content-wrapper .vbogs-main-content .woocommerce-Button,
.woocommerce-account .vbogs-content-wrapper .vbogs-main-content .woocommerce-Button.disabled,
.woocommerce-account .vbogs-content-wrapper .vbogs-main-content .button,
.vbogs-content-wrapper .woocommerce ul.products li.product .button,
.woocommerce-account .vbogs-content-wrapper .vbogs-main-content .woocommerce-order-details__title:after,
.woocommerce-account .vbogs-content-wrapper .vbogs-main-content .woocommerce-column__title:after,
.woocommerce-account .vbogs-content-wrapper .vbogs-main-content .woocommerce-Address-title h3:after,
.woocommerce-account .vbogs-content-wrapper .vbogs-main-content fieldset legend:after,
.woocommerce-account .vbogs-content-wrapper .vbogs-main-content form h3:after,
.woocommerce-account .vbogs-main-content h2:after,
.single-product .vbogs-blog-single-wrapper .related.products>h2:after,
.single-product .vbogs-blog-single-wrapper #tab-description h2:after,
.single-product .vbogs-blog-single-wrapper #tab-additional_information h2:after,
.vbogs-main-content .cart-collaterals .cart_totals h2:after,
.vbogs-content-wrapper .vbogs-main-content .woocommerce span.onsale,
.single-product .vbogs-blog-single-wrapper .single_add_to_cart_button.button.alt,
.vbogs-content-wrapper .vbogs-main-content .woocommerce table.shop_attributes tr:nth-child(2n) th,
.woocommerce-cart .vbogs-content-wrapper .vbogs-main-content .checkout-button,
.woocommerce-cart .vbogs-content-wrapper .vbogs-main-content .checkout-button:hover {
    background: #7F9A27;
}

.shb-accommodation-listing-style-1 .shb-accommodation-listing-item .shb-accommodation-listing-description-wrapper .shb-accommodation-listing-description ul li i,
.shb-accommodation-listing-style-2 .shb-accommodation-listing-item .shb-accommodation-listing-description-wrapper .shb-accommodation-listing-description ul li i,
.shb-booking-form-style-1 .shb-booking-form-col i,
.shb-title-with-icons-wrapper i,
.vbogs-main-content table td i,
.vbogs-content-wrapper .shb-booking-contact-wrapper ul li i,
.shb-booking-accommodation-result-wrapper .shb-booking-accommodation-item .shb-booking-accommodation-image .shb-booking-accommodation-image-icon i,
.shb-booking-accommodation-result-wrapper .shb-booking-accommodation-item .shb-booking-condition-wrapper h2 i,
.shb-booking-accommodation-result-wrapper .shb-booking-accommodation-item .shb-booking-condition-wrapper ul li:before,
.shb-booking-accommodation-result-wrapper .shb-booking-accommodation-item .shb-booking-accommodation-info-wrapper .shb-booking-accommodation-info ul li i,
.shb-booking-notification-wrapper p i,
.shb-booking-page-main .woocommerce .woocommerce-info:before,
.woocommerce .woocommerce-error:before,
.woocommerce-account .vbogs-content-wrapper .vbogs-main-content .woocommerce-customer-details .woocommerce-customer-details--phone:before,
.woocommerce-account .vbogs-content-wrapper .vbogs-main-content .woocommerce-customer-details .woocommerce-customer-details--email:before,
.woocommerce-account .vbogs-content-wrapper .vbogs-main-content .woocommerce-Message:before,
.woocommerce-account .vbogs-content-wrapper .vbogs-main-content .woocommerce-message:before,
.vbogs-content-wrapper .vbogs-main-content .woocommerce ul.products li.product .price,
.vbogs-content-wrapper .vbogs-main-content .woocommerce div.product p.price {
    color: #7F9A27;
}

.shb-booking-form-style-1 .shb-location-select-dropdown,
.shb-booking-form-style-1 .shb-guestclass-select-dropdown,
.shb-booking-step-wrapper .shb-booking-step.shb-booking-step-current a:first-child,
.shb-booking-accommodation-result-wrapper .shb-booking-accommodation-item .shb-booking-condition-wrapper,
.shb-booking-accommodation-result-wrapper .shb-booking-accommodation-item.shb-booking-accommodation-item-highlight {
    border: #7F9A27 2px solid;
}

.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper,
.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-top-right-navigation,
.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-top-right-navigation,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1 {
    border-color: rgba(255, 255, 255, 0.2);
}

.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header h2.vbogs-logo a,
.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-navigation li a,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header h2.vbogs-logo a,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-navigation li a {
    color: #fff;
}

.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper,
.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-top-right-navigation,
.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-top-right-navigation,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1 {
    border-color: #dedede;
}

.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header h2.vbogs-logo a,
.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header h2.vbogs-logo a,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a {
    color: #181b20;
}

.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li.current_page_item>a,
.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a:hover,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li.current_page_item>a,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a:hover {
    color: #8a8989;
}

.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper,
.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-top-right-navigation,
.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-top-right-navigation,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1 {
    border-color: #dedede;
}

.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation:hover h2.vbogs-logo a,
.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li a,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation:hover h2.vbogs-logo a,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li a {
    color: #181b20;
}

.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li.current_page_item>a,
.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li a:hover,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li.current_page_item>a,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li a:hover {
    color: #8a8989;
}

.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li li a:hover,
.page-id-2099.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li li a:hover,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li li a:hover,
.page-id-2099.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li li a:hover {
    color: #fff;
}

.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper,
.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-top-right-navigation,
.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-top-right-navigation,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1 {
    border-color: rgba(255, 255, 255, 0.2);
}

.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header h2.vbogs-logo a,
.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-navigation li a,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header h2.vbogs-logo a,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-navigation li a {
    color: #fff;
}

.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper,
.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-top-right-navigation,
.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-top-right-navigation,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1 {
    border-color: #dedede;
}

.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header h2.vbogs-logo a,
.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header h2.vbogs-logo a,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a {
    color: #181b20;
}

.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li.current_page_item>a,
.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a:hover,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li.current_page_item>a,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a:hover {
    color: #8a8989;
}

.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper,
.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-top-right-navigation,
.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-top-right-navigation,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1 {
    border-color: #dedede;
}

.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation:hover h2.vbogs-logo a,
.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li a,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation:hover h2.vbogs-logo a,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li a {
    color: #181b20;
}

.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li.current_page_item>a,
.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li a:hover,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li.current_page_item>a,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li a:hover {
    color: #8a8989;
}

.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li li a:hover,
.page-id-2100.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li li a:hover,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li li a:hover,
.page-id-2100.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li li a:hover {
    color: #fff;
}

.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper,
.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-top-right-navigation,
.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-top-right-navigation,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1 {
    border-color: rgba(255, 255, 255, 0.2);
}

.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header h2.vbogs-logo a,
.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-navigation li a,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header h2.vbogs-logo a,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-navigation li a {
    color: #fff;
}

.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper,
.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-top-right-navigation,
.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-top-right-navigation,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1 {
    border-color: #dedede;
}

.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header h2.vbogs-logo a,
.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header h2.vbogs-logo a,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a {
    color: #181b20;
}

.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li.current_page_item>a,
.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a:hover,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li.current_page_item>a,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a:hover {
    color: #8a8989;
}

.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper,
.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-top-right-navigation,
.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-top-right-navigation,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1 {
    border-color: #dedede;
}

.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation:hover h2.vbogs-logo a,
.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li a,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation:hover h2.vbogs-logo a,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li a {
    color: #181b20;
}

.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li.current_page_item>a,
.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li a:hover,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li.current_page_item>a,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li a:hover {
    color: #8a8989;
}

.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li li a:hover,
.page-id-2101.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li li a:hover,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li li a:hover,
.page-id-2101.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li li a:hover {
    color: #fff;
}

.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper,
.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-top-right-navigation,
.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-top-right-navigation,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1 {
    border-color: rgba(255, 255, 255, 0.2);
}

.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header h2.vbogs-logo a,
.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-navigation li a,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header h2.vbogs-logo a,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-navigation li a {
    color: #fff;
}

.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper,
.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-top-right-navigation,
.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-top-right-navigation,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1 {
    border-color: #dedede;
}

.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header h2.vbogs-logo a,
.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header h2.vbogs-logo a,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a {
    color: #181b20;
}

.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li.current_page_item>a,
.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a:hover,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li.current_page_item>a,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a:hover {
    color: #8a8989;
}

.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper,
.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-top-right-navigation,
.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-top-right-navigation,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1 {
    border-color: #dedede;
}

.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation:hover h2.vbogs-logo a,
.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li a,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation:hover h2.vbogs-logo a,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li a {
    color: #181b20;
}

.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li.current_page_item>a,
.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li a:hover,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li.current_page_item>a,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li a:hover {
    color: #8a8989;
}

.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li li a:hover,
.page-id-2102.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li li a:hover,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li li a:hover,
.page-id-2102.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li li a:hover {
    color: #fff;
}

.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper,
.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-top-right-navigation,
.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-top-right-navigation,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1 {
    border-color: rgba(255, 255, 255, 0.2);
}

.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header h2.vbogs-logo a,
.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-navigation li a,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header h2.vbogs-logo a,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-navigation li a {
    color: #fff;
}

.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper,
.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-top-right-navigation,
.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-top-right-navigation,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1 {
    border-color: #dedede;
}

.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header h2.vbogs-logo a,
.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header h2.vbogs-logo a,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a {
    color: #181b20;
}

.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li.current_page_item>a,
.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a:hover,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li.current_page_item>a,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a:hover {
    color: #8a8989;
}

.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper,
.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-top-right-navigation,
.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-top-right-navigation,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1 {
    border-color: #dedede;
}

.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation:hover h2.vbogs-logo a,
.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li a,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation:hover h2.vbogs-logo a,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li a {
    color: #181b20;
}

.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li.current_page_item>a,
.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li a:hover,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li.current_page_item>a,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li a:hover {
    color: #8a8989;
}

.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li li a:hover,
.page-id-2103.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li li a:hover,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li li a:hover,
.page-id-2103.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li li a:hover {
    color: #fff;
}

.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper,
.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-top-right-navigation,
.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-top-right-navigation,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1 {
    border-color: rgba(255, 255, 255, 0.2);
}

.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header h2.vbogs-logo a,
.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation.vbogs-header .vbogs-navigation li a,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header h2.vbogs-logo a,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation.vbogs-header .vbogs-navigation li a {
    color: #fff;
}

.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper,
.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-top-right-navigation,
.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-top-right-navigation,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1 {
    border-color: #dedede;
}

.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header h2.vbogs-logo a,
.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header h2.vbogs-logo a,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a {
    color: #181b20;
}

.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li.current_page_item>a,
.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a:hover,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li.current_page_item>a,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation-clone.vbogs-header .vbogs-navigation li a:hover {
    color: #8a8989;
}

.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper,
.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-top-right-navigation,
.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-top-right-navigation,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1 {
    border-color: #dedede;
}

.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation:hover h2.vbogs-logo a,
.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li a,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li a,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation:hover h2.vbogs-logo a,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar .vbogs-top-right-wrapper .vbogs-top-right-button1,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li a {
    color: #181b20;
}

.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li.current_page_item>a,
.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li a:hover,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li.current_page_item>a,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li a:hover {
    color: #8a8989;
}

.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li li a:hover,
.page-id-2104.vbogs-header-2 .vbogs-fixed-navigation:hover .vbogs-navigation li li a:hover,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-topbar-wrapper .vbogs-topbar li li a:hover,
.page-id-2104.vbogs-header-4 .vbogs-fixed-navigation:hover .vbogs-navigation li li a:hover {
    color: #fff;
}

body .tnp-widget label {
    display: none;
}

.woocommerce form .form-row .required {
    visibility: visible;
}

body {
    --wp--preset--color--black: #000000;
    --wp--preset--color--cyan-bluish-gray: #abb8c3;
    --wp--preset--color--white: #ffffff;
    --wp--preset--color--pale-pink: #f78da7;
    --wp--preset--color--vivid-red: #cf2e2e;
    --wp--preset--color--luminous-vivid-orange: #ff6900;
    --wp--preset--color--luminous-vivid-amber: #fcb900;
    --wp--preset--color--light-green-cyan: #7bdcb5;
    --wp--preset--color--vivid-green-cyan: #00d084;
    --wp--preset--color--pale-cyan-blue: #8ed1fc;
    --wp--preset--color--vivid-cyan-blue: #0693e3;
    --wp--preset--color--vivid-purple: #9b51e0;
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg, rgba(6, 147, 227, 1) 0%, rgb(155, 81, 224) 100%);
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg, rgb(122, 220, 180) 0%, rgb(0, 208, 130) 100%);
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg, rgba(252, 185, 0, 1) 0%, rgba(255, 105, 0, 1) 100%);
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg, rgba(255, 105, 0, 1) 0%, rgb(207, 46, 46) 100%);
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg, rgb(238, 238, 238) 0%, rgb(169, 184, 195) 100%);
    --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg, rgb(74, 234, 220) 0%, rgb(151, 120, 209) 20%, rgb(207, 42, 186) 40%, rgb(238, 44, 130) 60%, rgb(251, 105, 98) 80%, rgb(254, 248, 76) 100%);
    --wp--preset--gradient--blush-light-purple: linear-gradient(135deg, rgb(255, 206, 236) 0%, rgb(152, 150, 240) 100%);
    --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg, rgb(254, 205, 165) 0%, rgb(254, 45, 45) 50%, rgb(107, 0, 62) 100%);
    --wp--preset--gradient--luminous-dusk: linear-gradient(135deg, rgb(255, 203, 112) 0%, rgb(199, 81, 192) 50%, rgb(65, 88, 208) 100%);
    --wp--preset--gradient--pale-ocean: linear-gradient(135deg, rgb(255, 245, 203) 0%, rgb(182, 227, 212) 50%, rgb(51, 167, 181) 100%);
    --wp--preset--gradient--electric-grass: linear-gradient(135deg, rgb(202, 248, 128) 0%, rgb(113, 206, 126) 100%);
    --wp--preset--gradient--midnight: linear-gradient(135deg, rgb(2, 3, 129) 0%, rgb(40, 116, 252) 100%);
    --wp--preset--duotone--dark-grayscale: url('#wp-duotone-dark-grayscale');
    --wp--preset--duotone--grayscale: url('#wp-duotone-grayscale');
    --wp--preset--duotone--purple-yellow: url('#wp-duotone-purple-yellow');
    --wp--preset--duotone--blue-red: url('#wp-duotone-blue-red');
    --wp--preset--duotone--midnight: url('#wp-duotone-midnight');
    --wp--preset--duotone--magenta-yellow: url('#wp-duotone-magenta-yellow');
    --wp--preset--duotone--purple-green: url('#wp-duotone-purple-green');
    --wp--preset--duotone--blue-orange: url('#wp-duotone-blue-orange');
    --wp--preset--font-size--small: 13px;
    --wp--preset--font-size--medium: 20px;
    --wp--preset--font-size--large: 36px;
    --wp--preset--font-size--x-large: 42px;
    --wp--preset--spacing--20: 0.44rem;
    --wp--preset--spacing--30: 0.67rem;
    --wp--preset--spacing--40: 1rem;
    --wp--preset--spacing--50: 1.5rem;
    --wp--preset--spacing--60: 2.25rem;
    --wp--preset--spacing--70: 3.38rem;
    --wp--preset--spacing--80: 5.06rem;
}

:where(.is-layout-flex) {
    gap: 0.5em;
}

body .is-layout-flow>.alignleft {
    float: left;
    margin-inline-start: 0;
    margin-inline-end: 2em;
}

body .is-layout-flow>.alignright {
    float: right;
    margin-inline-start: 2em;
    margin-inline-end: 0;
}

body .is-layout-flow>.aligncenter {
    margin-left: auto !important;
    margin-right: auto !important;
}

body .is-layout-constrained>.alignleft {
    float: left;
    margin-inline-start: 0;
    margin-inline-end: 2em;
}

body .is-layout-constrained>.alignright {
    float: right;
    margin-inline-start: 2em;
    margin-inline-end: 0;
}

body .is-layout-constrained>.aligncenter {
    margin-left: auto !important;
    margin-right: auto !important;
}

body .is-layout-constrained> :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
    max-width: var(--wp--style--global--content-size);
    margin-left: auto !important;
    margin-right: auto !important;
}

body .is-layout-constrained>.alignwide {
    max-width: var(--wp--style--global--wide-size);
}

body .is-layout-flex {
    display: flex;
}

body .is-layout-flex {
    flex-wrap: wrap;
    align-items: center;
}

body .is-layout-flex>* {
    margin: 0;
}

:where(.wp-block-columns.is-layout-flex) {
    gap: 2em;
}

.has-black-color {
    color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-color {
    color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-color {
    color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-color {
    color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-color {
    color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-color {
    color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-color {
    color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-color {
    color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-color {
    color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-color {
    color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-color {
    color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-color {
    color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-background-color {
    background-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-background-color {
    background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-background-color {
    background-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-background-color {
    background-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-background-color {
    background-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-background-color {
    background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-background-color {
    background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-background-color {
    background-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-background-color {
    background-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-background-color {
    background-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-background-color {
    background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-background-color {
    background-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-border-color {
    border-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-border-color {
    border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-border-color {
    border-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-border-color {
    border-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-border-color {
    border-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-border-color {
    border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-border-color {
    border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-border-color {
    border-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-border-color {
    border-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-border-color {
    border-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-border-color {
    border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-border-color {
    border-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
    background: var(--wp--preset--gradient--vivid-cyan-blue-to-vivid-purple) !important;
}

.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
    background: var(--wp--preset--gradient--light-green-cyan-to-vivid-green-cyan) !important;
}

.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
    background: var(--wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange) !important;
}

.has-luminous-vivid-orange-to-vivid-red-gradient-background {
    background: var(--wp--preset--gradient--luminous-vivid-orange-to-vivid-red) !important;
}

.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
    background: var(--wp--preset--gradient--very-light-gray-to-cyan-bluish-gray) !important;
}

.has-cool-to-warm-spectrum-gradient-background {
    background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
}

.has-blush-light-purple-gradient-background {
    background: var(--wp--preset--gradient--blush-light-purple) !important;
}

.has-blush-bordeaux-gradient-background {
    background: var(--wp--preset--gradient--blush-bordeaux) !important;
}

.has-luminous-dusk-gradient-background {
    background: var(--wp--preset--gradient--luminous-dusk) !important;
}

.has-pale-ocean-gradient-background {
    background: var(--wp--preset--gradient--pale-ocean) !important;
}

.has-electric-grass-gradient-background {
    background: var(--wp--preset--gradient--electric-grass) !important;
}

.has-midnight-gradient-background {
    background: var(--wp--preset--gradient--midnight) !important;
}

.has-small-font-size {
    font-size: var(--wp--preset--font-size--small) !important;
}

.has-medium-font-size {
    font-size: var(--wp--preset--font-size--medium) !important;
}

.has-large-font-size {
    font-size: var(--wp--preset--font-size--large) !important;
}

.has-x-large-font-size {
    font-size: var(--wp--preset--font-size--x-large) !important;
}

.wp-block-navigation a:where(:not(.wp-element-button)) {
    color: inherit;
}

:where(.wp-block-columns.is-layout-flex) {
    gap: 2em;
}

.wp-block-pullquote {
    font-size: 1.5em;
    line-height: 1.6;
}

.owl-nav.disabled, .owl-dots.disabled {
    display: none;
    
}
.owl-dots {
    text-align: center;
    margin-bottom: 20px;
}

button.owl-dot {
    border: none;
    background: transparent;
    cursor: pointer;
}

ol.flex-control-nav.flex-control-paging {
    display: none;
}
.flex-pauseplay {
    display: none;
}
@media (max-width:1366px) {
	.vbogs-header .vbogs-navigation li a {
		font-size: 14px;
		font-weight: 600;
		padding: 30px 20px 25px 20px;
	}
	.vbogs-slider-caption-1 h2 {
		font-size: 55px;
	}
}


button.mat-calendar-body-cell:not(.mat-calendar-body-disabled) .mat-calendar-body-cell-content.mat-focus-indicator {
    background: #0d580d;
    color: #fff;
}

.mat-form-field-outline {
    display: none !important;
}

.mat-tab-label {
    border: 1px solid #ccc;
    border-radius: 10px 10px 0 0;
}

.mat-tab-label.mat-tab-disabled {
    cursor: default;
    display: none;
}

.mat-tab-label.mat-tab-label-active {
    background: #7F9A27;
    opacity: 1;
    color: #fff;
}

.mat-tab-body-content .wpb_wrapper {
    padding: 10px 0;
}



.mat-form-field-infix {
    width: 100%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0;
    border-top: none;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 !important;
}

.mat-form-field {
    width: 100%;
    input {
        margin-top: 0 !important;
        width: 100%;
    }
    mat-datepicker-toggle.mat-datepicker-toggle {
        position: absolute;
        top: 5px;
        right: -25px;
    }
}